import { getImageProviderHost } from '@zupr/api/host'
import { ProductImage } from '@zupr/types/bo'
import { Image, ProviderImage } from '@zupr/types/fo'

export type Format = 'webp' | 'jpg' | 'png' | 'gif'
export type Transformation =
    | 'rs:fill:32:32/enlarge:1' // favicon
    | 'rs:fit:380:380/enlarge:1' // what used to be thumbnail_small
    | 'rs:fit:620:620/enlarge:1' // what used to be  thumbnail_medium
    | 'rs:fit:1240:1240/enlarge:1' // what used to be  thumbnail_large
    | 'rs:fit:2048:2048' // OG:image
    | 'rs:fill:170:170/enlarge:1' // bo thumbail

export const imageProviderHashToUrl = (
    image: ProviderImage | Image | ProductImage,
    format: Format,
    transformation: Transformation
): string | null => {
    const id =
        'image_provider_hash' in image ? image.image_provider_hash : image.id

    if (!id) return null

    const parts = [id.slice(0, 2), id.slice(2, 4), id.slice(4)]

    // add the transformation
    if (transformation) {
        parts.splice(2, 0, transformation)
    }

    return `${getImageProviderHost()}/${parts.join('/')}.${format}`
}
